import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'

import config from 'config'
import createStore from './store/createStore'
import AppContainer from './containers/AppContainer'

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__
const store = createStore(initialState)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')

const htmlElement = document.querySelector('html')
htmlElement.style = `--primaryColor: ${config.clientConfig.primaryColor}`

let environment = 'production'
environment = `${__DEV__}` === 'true' ? 'dev' : environment

const favicon = document.querySelector('link[rel="icon"]')
favicon.href = config.clientConfig.faviconPath

document.title = config.clientConfig.title

Sentry.init({
	dsn: 'https://a204cafba5d14fed92ca81c513802760@o43978.ingest.sentry.io/151683',
	integrations: [],
	release: config.idCommit,
	environment,
	enabled: config.logger,
	normalizeDepth: 6,
})

let render = () => {
	const routes = require('./routes/index').default(store)

	ReactDOM.render(<AppContainer store={store} routes={routes} />, MOUNT_NODE)
}

// This code is excluded from production bundle
if (__DEV__) {
	if (module.hot) {
		// Development render functions
		const renderApp = render
		const renderError = error => {
			const RedBox = require('redbox-react').default

			ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
		}

		// Wrap render in try/catch
		render = () => {
			try {
				renderApp()
			} catch (error) {
				console.error(error) // eslint-disable-line no-console
				renderError(error)
			}
		}

		// Setup hot module replacement
		module.hot.accept('./routes/index', () =>
			setImmediate(() => {
				ReactDOM.unmountComponentAtNode(MOUNT_NODE)
				render()
			}),
		)
	}
}

// ========================================================
// Go!
// ========================================================
render()
